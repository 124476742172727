import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`Returning to a little more of a typical pattern, this week make `}<strong parentName="p">{`at least 5 substantive posts`}</strong>{` for this lesson's threads as follows:`}</p>
    <ul>
      <li parentName="ul">{`Reply to at least 2 of the numbered questions posed in the threads below by 5pm Tuesday.`}</li>
      <li parentName="ul">{`Then keep an eye on these and the other threads and respond to other students' posts or the professor's new questions with at least 3 more replies by 5pm Friday.`}</li>
    </ul>
    <h3>{`4.1 Modeling of Data`}</h3>
    <p>{`Data representation and visualization is a very broad topic for designers to consider. Our brief glimpse of this from Holtzblatt exposes a series of models specifically useful for digesting the data we've collected during user research. Without addressing a specific model, consider and then describe one specific benefit of taking the time to model data.`}</p>
    <h3>{`4.2 Specific Approaches to Data Modeling`}</h3>
    <p>{`After skimming Holtzblatt ch. 6--8 choose one specific modeling technique or type and describe it. Consider its purpose, effective use, and ideally, find one example from the web to share with the class.`}</p>
    <h3>{`4.3 Data Visualization`}</h3>
    <p>{`Broader than the specific focus of this course, data visualization is a fascinating topic to study. Certainly you've found some interesting or helpful data visualizations. Recall an example and describe it, if not actually providing a link to it on the web.`}</p>
    <p>{`If you're not able to recall any examples, `}<a parentName="p" {...{
        "href": "http://www.informationisbeautiful.net/"
      }}>{`browse this showcase of data visualizations`}</a>{` to find one interesting to you. Post the link and describe what caught your attention about it.`}</p>
    <h3>{`4.4 Key Takeaways from Norman and Holtzblatt`}</h3>
    <p>{`Reflect on what you've learned in the last 4 lessons from Don Norman (and company) and from the Holtzblatt text. Write a short reaction to share some key things that have been on your mind as you digest this material. What was new? Has your perspective on related topics changed? Or are you perhaps underwhelmed and unaffected by these topics?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      